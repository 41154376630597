import "./download-tile.scss";

import React from "react";

const DownloadTile = ({ name, link }) => {
   return (
      <div className="download-tile">
         <h3 className="download-tile__name">{name}</h3>
         <a
            href={link}
            download
            target="_blank"
            rel="noreferrer nofollow"
            className="button button--small"
         >
            Pobierz
         </a>
      </div>
   );
};

export default DownloadTile;
