import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import DownloadTile from "components/DownloadTile";

const DownloadPage = ({ pageContext }) => {
   const seo = pageContext.seo;
   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="for-who-section">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="row justify-content-center">
                  <div className="col-xl-8">
                     {pageContext.data.files.map((item, index) => (
                        <DownloadTile
                           name={item.name}
                           link={item.file?.mediaItemUrl}
                           key={index}
                        />
                     ))}
                  </div>
               </div>
            </div>
         </section>
      </Layout>
   );
};

export default DownloadPage;
